<template>
    <v-container fluid>
      <v-card-actions>
        <v-row class="mt-1">
          <v-col>
            <btn-voltar to="/planos" />
          </v-col>
        </v-row>
      </v-card-actions>
      <planos-campos
        :carregando-salvar="carregando"
        :validacao-formulario="validacaoFormulario"
        @salvar="salvar"
      />
    </v-container>
  </template>
  
  <script>
  import planos from "@/api/planos";
  
  export default {
    components: {
      PlanosCampos: () => import("./PlanosCampos"),
    },
  
    data() {
      return {
        carregando: false,
        validacaoFormulario: {},
      };
    },
  
    methods: {
      async salvar(plano) {
        this.carregando = true;
        this.validacaoFormulario = {};
  
        try {
          await planos.inserir(plano);
  
          this.$router.push("/planos", () =>
            this.$snackbar.mostrar({
              cor: "success",
              mensagem: this.$mensagens._("sucesso_adicionar"),
            })
          );
        } catch (e) {
          if (e.response.status === 422) {
            this.validacaoFormulario = this.$erroApi.validacao(e);
            return;
          }
  
          this.$snackbar.mostrar({
            cor: "error",
            mensagem: this.$erroApi._(e),
          });
        } finally {
          this.carregando = false;
        }
      },
    },
  };
  </script>
  